/** @jsxImportSource @emotion/react */
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGlobalState } from "../../globalState";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
export function TopBar() {
  const [_, setSideBarOpen] = useGlobalState("sideBar");
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            css={styles.icon}
            onClick={() => setSideBarOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <img src="/images/logo-small.png" style={{ height: 40 }} />
          <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1 }}
            css={styles.text}
            onClick={() => {
              navigate("/");
            }}
          >
            Acumen Glass
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            css={styles.icon}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
