/** @jsxImportSource @emotion/react */
import { Typography, Paper } from "@mui/material";
import { styles } from "./styles";

interface Props {}
export const Mobile: React.FC<Props> = () => {
  return (
    <div>
      <div css={styles.container}>
        <Typography color="primary" css={styles.text}>
          Mobile
        </Typography>
        <Typography css={styles.mainParagraph}>
          Acumen Auto Glass's fast and reliable mobile service is the convenient
          solution. Who wants to fight bay area traffic? Whether your vehicle is
          serviced at home, office parking lot, or at one of our locations, the
          quality and outcome of repairs is always the best. Call us, schedule
          an appointment, fixed - it’s that easy. (831) 460-1200
        </Typography>
      </div>
      <Paper elevation={4} css={styles.container}>
        <Typography color="primary" css={styles.bulletText}>
          Some Important Points
        </Typography>
        <ul css={styles.ul}>
          <li css={styles.li}>
            Acumen auto glass technicians need room to work. We cant't do
            repairs on the side of busy streets. Please be sure there's adequate
            space. Our ideal workspace is about the area of three parking
            spaces.
          </li>
          <li css={styles.li}>
            Water can comprise quality. If it's rainy please cover the window or
            have the vehicle undercover. Or, we'd be happy to re-schedule to a
            better day.
          </li>
          <li css={styles.li}>
            Luxury vehicles glass replacement often take hours. Dropping off at
            one of our locations maybe the better solution.
          </li>
        </ul>
      </Paper>
    </div>
  );
};
