import { createStyles } from "../../../emotion-styles";

export const styles = createStyles({
  container: {
    flexFlow: "column nowrap",
    padding: 8,
    //	marginTop:theme.spacing(4),
  },
  content: {
    flexFlow: "column wrap",
  },
  h1: {
    fontSize: "1.25rem",
    fontFamily: "SFBold",
    alignSelf: "center",
    marginBottom: 8,
  },
  h2: {
    fontSize: "1.00rem",
    fontFamily: "SFBold",
    marginBottom: 4,
  },
  h3: {
    fontSize: ".75rem",
    fontFamily: "SFBold",
    marginTop: 8,
    marginBottom: 2,
  },
  h4: {
    fontSize: ".75rem",
    fontFamily: "SFRegular",
    marginBottom: 1,
    alignSelf: "flex-end",
  },
  p: {
    fontSize: ".75rem",
    fontFamily: "SFRegular",
    marginBottom: 4,
  },
  link: {
    color: "red",
  },
  ul: {
    paddingLeft: 16,
    margin: 0,
  },
  li: {
    // marginTop:theme.spacing(.5),
    // marginBottom:theme.spacing(.5),
    fontSize: ".75rem",
    fontFamily: "SFRegular",
  },
  marginRight: {
    marginRight: 4,
  },
  header: {
    marginTop: 8,
  },
});
