import { createStyles } from "../../emotion-styles";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  container: {
    //   backgroundColor: blue[600],
    padding: "2em",
    //  margin: "45px",
  },
  text: {
    // color: lightBlue[400],
    fontSize: "2.0rem",
    //  color: "text.primary",
    textAlign: "center",
  },
  mainParagraph: {
    fontFamily: "SFRegular",
    fontSize: "1rem",
  },
  bulletText: {
    // color: lightBlue[400],
    fontSize: "1.25rem",
    //  color: "text.primary",
    // textAlign: "center",
  },
  ul: {
    listStyleImage: "url(images/bullets/acumen.png)",
  },
  li: {
    marginBottom: 8,
  },
});
