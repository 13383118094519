/** @jsxImportSource @emotion/react */
import { Typography, Paper } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styles } from "./styles";

interface Props {}
export const Services: React.FC<Props> = () => {
  return (
    <div css={styles.container}>
      <Typography color="primary" css={styles.text}>
        Services
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography css={styles.summary}>
            Automotive Glass Replacement
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src="images/services/replace.png" css={styles.image}></img>
          <Typography>
            Acumen Auto Glass offers a full range of auto glass replacement
            services for all makes and all models. Automotive glass replacement
            is not the easiest task to perform on vehicles. There is actually a
            great amount of knowledge, experience, and integrity that is needed
            in order to successfully complete a glass replacement in an
            automobile. There is no room for error with replacements. Precise
            workmanship and attention to detail is a must in order to allow our
            replacements to hold up to the harsh environments that cars endure.
            Through time, the automotive glass industry has evolved through many
            different types of installation technics. In the beginning auto
            glass was mainly installed with rubber gaskets and sticky sealants.
            Then in the early 70’s Butyl was adopted as the leading standard for
            front and rear windshield installations. So rather than a rubber
            gasket holding the glass in place, a bead of butyl, a bubble gum
            like sealant was the popular way glass was installed in vehicles.
            Butyl thought had many drawbacks and was not to reliable against
            preventing water leaks over time. It did not take long until l the
            flawed concept of butyl installation came to its end as the industry
            standard. With auto makers pushing for new designs in the 80’s as
            well new standards from the department of transportation.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography css={styles.summary}>Windshield Repair</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src="images/services/repair.jpg" css={styles.image}></img>

          <Typography>
            Don’t wait too long to repair that rock chip in your windshield,
            acting fast could save you hundreds of your valued cash. Rock chips
            are small fractures that are found in windshields that most commonly
            occur while driving. Flying objects like small rocks and loose road
            debris that get kicked up by moving traffic usually are the cause.
            All though small in size, Rock Chips pose a serious threat to the
            overall integrity and appearance of your windshield. Normally caused
            by temperature changes of daily weather, expansion and retraction of
            the windshield are one of the many ways a rock chip can spread over
            time. Another typical cause of crack expansion is due to the fact
            the windshield carries some of the structural stress of the vehicle.
            Every day driving induces stress across the windshield increasing
            the chance of a rock chip growing into an irritation. Since a full
            Lifetime Warranty against spreading backs our Windshield Repairs,
            you can easily rely on our four-step repair process. Not to mention
            you will not lose the money you have invested into the repair
            either. We don’t want to waist your time and ours on poor quality
            repairs, so through much testing and experience we found that
            successful repairs depend on the quality of the repair equipment,
            strength of the glass resin, and skills of the technician. So why
            risk it? Schedule today and find out how easy it is to check this
            off your “to do list”.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography css={styles.summary}>
            Window Regulator Replacement
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src="images/services/door.jpg" css={styles.image}></img>

          <Typography>
            Acumen Auto Glass offers a full range of auto glass replacement
            services for all makes and all models. Automotive glass replacement
            is not the easiest task to perform on vehicles. There is actually a
            great amount of knowledge, experience, and integrity that is needed
            in order to successfully complete a glass replacement in an
            automobile. There is no room for error with replacements. Precise
            workmanship and attention to detail is a must in order to allow our
            replacements to hold up to the harsh environments that cars endure.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography css={styles.summary}>
            Automotive Body Glass Services
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src="images/services/body.jpg" css={styles.image}></img>

          <Typography>
            Acumen Auto Glass offers a full range of auto glass replacement
            services for all makes and all models. Automotive glass replacement
            is not the easiest task to perform on vehicles. There is actually a
            great amount of knowledge, experience, and integrity that is needed
            in order to successfully complete a glass replacement in an
            automobile. There is no room for error with replacements. Precise
            workmanship and attention to detail is a must in order to allow our
            replacements to hold up to the harsh environments that cars endure.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography css={styles.summary}>Fleet Services</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src="images/services/fleet.png" css={styles.image}></img>

          <Typography>
            Acumen Auto Glass offers a full range of auto glass replacement
            services for all makes and all models. Automotive glass replacement
            is not the easiest task to perform on vehicles. There is actually a
            great amount of knowledge, experience, and integrity that is needed
            in order to successfully complete a glass replacement in an
            automobile. There is no room for error with replacements. Precise
            workmanship and attention to detail is a must in order to allow our
            replacements to hold up to the harsh environments that cars endure.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
