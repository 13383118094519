import { createStyles } from "../emotion-styles";
import { lightBlue, green, amber, yellow } from "@mui/material/colors";

export const styles = createStyles({
  text: {
    color: "rgb(252,190,7)", //yellow[500],
    fontFamily: "SFBold",
    cursor: "pointer",
  },
  icon: {
    color: "rgb(252,190,7)", //yellow[500],
    marginRight: 0,
  },
});
