//import { useGlobalState } from "./globalState";
import React from "react";
import { AppRoutes } from "./components/routes";
import { SideBar } from "./components/sidebar";
import { TopBar } from "./components/topBar";
import { useLocation } from "react-router-dom";
interface Props {}

export const App: React.FC<Props> = () => {
  let location = useLocation();

  React.useEffect(() => {
    console.log(location);
    debugger;
    // Google Analytics
    //  ga('send', 'pageview');
  }, [location]);
  return (
    <div>
      <TopBar />
      <SideBar />
      <AppRoutes />
    </div>
  );
};

export default App;
