import { createStyles } from "../../emotion-styles";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  container: {
    //   backgroundColor: blue[600],
    padding: "2em",
    //  margin: "45px",
  },
  text: {
    // color: lightBlue[400],
    fontSize: "2.0rem",
    //  color: "text.primary",
    textAlign: "center",
  },

  slideshowContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  containerStyles: {
    width: "600px",
    height: "320px",
    margin: "0 auto",
  },
});
