import { createStyles } from "../../emotion-styles";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  container: {
    //   backgroundColor: blue[600],
    padding: "2em",
    //  margin: "45px",
  },
  text: {
    // color: lightBlue[400],
    fontSize: "2.0rem",
    //  color: "text.primary",
    textAlign: "center",
  },
  mainParagraph: {
    fontFamily: "SFRegular",
    fontSize: "1rem",
  },
  banner1: {
    fontFamily: "SFRegularItalic",
    fontSize: "1.25rem",
    textAlign: "center",
  },
  banner2: {
    fontFamily: "SFBoldItalic",
    fontSize: ".75rem",
    textAlign: "center",
    marginTop: 8,
    marginBottom: 8,
  },
  slideshowContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  image: {
    width: "100%",
    height: "320px",
    margin: "0 auto",
  },
});
