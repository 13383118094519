import { createGlobalState } from "react-hooks-global-state";
export const { useGlobalState } = createGlobalState({
  sideBar: false,
  decaf: "",
  regular: "",
  //   person: {
  //     age: 0,
  //     firstName: '',
  //     lastName: '',
  //   },
});
