/** @jsxImportSource @emotion/react */
import { Typography, Paper } from "@mui/material";
import { styles } from "./styles";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-slideshow-image/dist/styles.css";
import ImageSlider from "../../imageSlider";

const slideImages = [
  {
    url: "images/gallery/1.png",
    title: "Slide 1",
  },
  {
    url: "images/gallery/2.png",
    title: "Slide 2",
  },
  {
    url: "images/gallery/3.png",
    title: "Slide 3",
  },
  {
    url: "images/gallery/4.png",
    title: "Slide 4",
  },
];
interface Props {}

export const Gallery: React.FC<Props> = () => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <Paper elevation={4} css={styles.container}>
      <Typography color="primary" css={styles.text}>
        Gallery
      </Typography>
      <div
        css={styles.containerStyles}
        style={matches ? { width: "100%", height: 190 } : {}}
      >
        <ImageSlider slides={slideImages} />
      </div>
    </Paper>
  );
};
