/** @jsxImportSource @emotion/react */
import {
  Typography,
  Paper,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Button,
  TextareaAutosize,
} from "@mui/material"; //<FormGroup>

import { styles } from "./styles";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

interface EstimateInfo {
  cracked?: boolean;
  insurance?: boolean;
  urgent?: boolean;
  year?: string;
  model?: string;
  make?: string;
  body?: string;
  vin?: string;
  notes?: string;
}
interface Props {
  info: EstimateInfo | null;
  onComplete: (info: EstimateInfo) => void;
}

const inputs = {
  year: {
    validator: (value: any) => /^(19|20)\d{2}$/.test(value),
    message: "please enter a 4 digit year",
    label: "year",
    required: true,
  },
  make: {
    validator: (value: any) => /[a-zA-Z0-9]{2,}/.test(value),
    message: "please enter your vehicle's make - chevy, ford, bmw",
    label: "make",
    required: true,
  },
  model: {
    validator: (value: any) => /[a-zA-Z0-9]{1,}/.test(value),
    message: "please enter your vehicle's model - F150, Camaro, 323i",
    label: "model",
    required: true,
  },
  body: {
    validator: (value: any) => /[a-zA-Z0-9]{1,}/.test(value),
    message: "please enter your vehicle's body type - 2 door, SUV, van",
    label: "body",
    required: true,
  },
  vin: {
    validator: (value: string) => value.length > 0,
    message: "please enter your car's vin number",
    required: false,
    label: "vin",
  },
};
export const EstimateInfoInput: React.FC<Props> = ({ onComplete, info }) => {
  const [valid, setValid] = useState(false);
  const [cracked, setCracked] = useState(info?.cracked ? info.cracked : false);
  const [insurance, setInsurance] = useState(
    info?.insurance ? info.insurance : false
  );
  const [urgent, setUrgent] = useState(info?.urgent ? info.urgent : false);
  const [year, setYear] = useState(info?.year ? info.year : "");
  const [make, setMake] = useState(info?.make ? info.make : "");
  const [model, setModel] = useState(info?.model ? info.model : "");
  const [body, setBody] = useState(info?.body ? info?.body : "");
  const [vin, setVin] = useState(info?.vin ? info.vin : "");
  const [notes, setNotes] = useState(info?.notes ? info.notes : "");
  //const navigate = useNavigate();
  const smMatch = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  useEffect(() => {
    isValid();
  }, [year, make, model, body, vin, notes]);
  const onChangeCracked = (event: any) => {
    setCracked(event.target.checked);
  };
  const onChangeInsurance = (event: any) => {
    setInsurance(event.target.checked);
  };
  const onChangeUrgent = (event: any) => {
    setUrgent(event.target.checked);
  };
  const onChangeYear = (event: any) => {
    setYear(event.target.value);
  };
  const onChangeModel = (event: any) => {
    setModel(event.target.value);
  };
  const onChangeMake = (event: any) => {
    setMake(event.target.value);
  };
  const onChangeBody = (event: any) => {
    setBody(event.target.value);
  };
  const onChangeVin = (event: any) => {
    setVin(event.target.value);
  };
  const onChangeNotes = (event: any) => {
    setNotes(event.target.value);
  };
  const onClickSubmit = () => {
    // setZipcode(event.target.value);
    onComplete({
      insurance,
      cracked,
      urgent,
      make,
      model,
      year,
      body,
      vin,
      notes,
    });
  };
  const isValid = () => {
    if (
      inputs.year.validator(year) &&
      inputs.make.validator(make) &&
      inputs.model.validator(model) &&
      inputs.body.validator(body)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };
  const renderCheckboxInputs = () => {
    return (
      <Paper css={styles.checkboxes} elevation={4}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={cracked}
                onChange={onChangeCracked}
              />
            }
            label="Instead of a replacement, are you in need of a crack or rock chip repair on your windshield?"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={insurance}
                onChange={onChangeInsurance}
              />
            }
            label="Are you considering insurance?"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={urgent}
                onChange={onChangeUrgent}
              />
            }
            label="Is this glass replacement urgent?"
          />
        </FormGroup>
      </Paper>
    );
  };

  const renderAutoInfo = () => {
    return (
      <div css={styles.autoInfo}>
        <Typography css={styles.infoHeading}>Auto Information</Typography>
        <div css={styles.inputs}>
          <TextField
            label={inputs.year.label}
            css={styles.input}
            required={inputs.year.required}
            variant="filled"
            value={year}
            error={!inputs.year.validator(year)}
            helperText={inputs.year.message}
            onChange={onChangeYear}
          />
          <TextField
            label={inputs.make.label}
            required={inputs.make.required}
            css={styles.input}
            variant="filled"
            value={make}
            error={!inputs.make.validator(make)}
            helperText={inputs.make.message}
            onChange={onChangeMake}
          />
          <TextField
            label={inputs.model.label}
            css={styles.input}
            variant="filled"
            required={inputs.model.required}
            value={model}
            error={!inputs.model.validator(model)}
            helperText={inputs.model.message}
            onChange={onChangeModel}
          />
          <TextField
            label={inputs.body.label}
            css={styles.input}
            variant="filled"
            required={inputs.body.required}
            value={body}
            error={!inputs.body.validator(body)}
            helperText={inputs.body.message}
            onChange={onChangeBody}
          />
          <TextField
            label={inputs.vin.label}
            css={styles.input}
            variant="filled"
            value={vin}
            required={inputs.vin.required}
            error={!inputs.vin.validator(vin)}
            helperText={inputs.vin.message}
            onChange={onChangeVin}
          />
          <div>
            <Typography css={styles.textAreaContainer}>notes</Typography>
            <TextareaAutosize
              maxRows={4}
              minRows={4}
              css={styles.notes}
              aria-label="notes"
              placeholder="anything we should know?"
              value={notes}
              onChange={onChangeNotes}
            />
          </div>
        </div>
      </div>
    );
  };
  const renderInfoInput = () => {
    return (
      <Paper elevation={4} css={styles.info}>
        {renderCheckboxInputs()}
        <Typography color="warning" css={styles.requiredFields}>
          required field *
        </Typography>
        {renderAutoInfo()}
        {renderSubmitButton()}
      </Paper>
    );
  };
  const renderSubmitButton = () => {
    if (!valid) return null;

    return (
      <div css={styles.buttons}>
        <Button
          css={styles.submitButton}
          variant="contained"
          onClick={onClickSubmit}
        >
          Submit
        </Button>
      </div>
    );
  };
  return <>{renderInfoInput()}</>;
};
