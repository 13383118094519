/** @jsxImportSource @emotion/react */
import {
  Typography,
  Paper,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Button,
} from "@mui/material"; //<FormGroup>

import { styles } from "./styles";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

interface ContactInfo {
  name: string;
  address: string;
  zipcode: string;
  phone: string;
  email: string;
}
interface Props {
  info: ContactInfo | null;
  onComplete: (info: ContactInfo) => void;
}

const inputs = {
  name: {
    validator: (value: any) => /[a-zA-Z0-9]{2,}/.test(value),
    message: "please enter your name",
    label: "name",

    required: true,
  },
  phone: {
    validator: (value: any) =>
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/.test(
        value
      ),
    message: "please enter a valid phone number",
    label: "phone number",
    required: true,
  },
  email: {
    validator: (value: any) => /\S+@\S+\.\S+/.test(value),
    message: "please enter a valid email address",
    label: "email address",
    required: true,
  },
  address: {
    validator: (value: any) => /[a-zA-Z0-9]{2,}/.test(value),
    message: "please enter your street address",
    required: true,
    label: "street address",
  },
  zipcode: {
    validator: (value: any) => /^[0-9]{5}(?:-[0-9]{4})?$/.test(value),
    message: "please enter your zipcode",
    required: true,
    label: "zipcode",
  },
};
export const ContactInfoInput: React.FC<Props> = ({ onComplete, info }) => {
  const [valid, setValid] = useState(false);
  const [name, setName] = useState(info?.name ? info.name : "");
  const [phone, setPhone] = useState(info?.phone ? info.phone : "");
  const [email, setEmail] = useState(info?.email ? info.email : "");
  const [address, setAddress] = useState(info?.address ? info.address : "");
  const [zipcode, setZipcode] = useState(info?.zipcode ? info?.zipcode : "");
  //const navigate = useNavigate();
  const smMatch = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  useEffect(() => {
    isValid();
  }, [name, phone, email, address, zipcode]);
  const onChangeName = (event: any) => {
    setName(event.target.value);
  };
  const onChangePhone = (event: any) => {
    setPhone(event.target.value);
  };
  const onChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };
  const onChangeAddress = (event: any) => {
    setAddress(event.target.value);
  };
  const onChangeZipcode = (event: any) => {
    setZipcode(event.target.value);
  };

  const onClickSubmit = () => {
    // setZipcode(event.target.value);
    onComplete({
      name,
      phone,
      email,
      address,
      zipcode,
    });
  };

  const isValid = () => {
    if (
      inputs.name.validator(name) &&
      inputs.phone.validator(phone) &&
      inputs.email.validator(email) &&
      inputs.address.validator(address) &&
      inputs.zipcode.validator(zipcode)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const renderContactInfo = () => {
    return (
      <div css={styles.contactInfo}>
        <Typography css={styles.infoHeading}>Contact Information</Typography>
        <div css={styles.inputs}>
          <TextField
            label={inputs.name.label}
            required={inputs.name.required}
            css={styles.input}
            variant="filled"
            value={name}
            error={!inputs.name.validator(name)}
            helperText={inputs.name.message}
            onChange={onChangeName}
          />
          <TextField
            label={inputs.phone.label}
            required={inputs.phone.required}
            css={styles.input}
            variant="filled"
            value={phone}
            error={!inputs.phone.validator(phone)}
            helperText={inputs.phone.message}
            onChange={onChangePhone}
          />
          <TextField
            label={inputs.email.label}
            required={inputs.email.required}
            css={styles.input}
            variant="filled"
            value={email}
            error={!inputs.email.validator(email)}
            helperText={inputs.email.message}
            onChange={onChangeEmail}
          />

          <TextField
            label={inputs.address.label}
            css={styles.input}
            required={inputs.address.required}
            variant="filled"
            value={address}
            error={!inputs.address.validator(address)}
            helperText={inputs.address.message}
            onChange={onChangeAddress}
          />
          <TextField
            label={inputs.zipcode.label}
            required={inputs.zipcode.required}
            css={styles.input}
            variant="filled"
            value={zipcode}
            error={!inputs.zipcode.validator(zipcode)}
            helperText={inputs.zipcode.message}
            onChange={onChangeZipcode}
          />
        </div>
      </div>
    );
  };

  const renderInfoInput = () => {
    return (
      <Paper elevation={4} css={styles.info}>
        <Typography color="warning" css={styles.requiredFields}>
          required field *
        </Typography>
        {renderContactInfo()}
        {renderSubmitButton()}
      </Paper>
    );
  };
  const renderSubmitButton = () => {
    if (!valid) return null;

    return (
      <div css={styles.buttons}>
        <Button
          css={styles.submitButton}
          variant="contained"
          onClick={onClickSubmit}
        >
          Submit
        </Button>
      </div>
    );
  };
  return <>{renderInfoInput()}</>;
};
