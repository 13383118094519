import { createStyles } from "../emotion-styles";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  slideStyles: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  rightArrowStyles: {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    right: "32px",
    fontSize: "45px",
    color: "#fff",
    zIndex: 1,
    cursor: "pointer",
  },

  leftArrowStyles: {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    left: "32px",
    fontSize: "45px",
    color: "#fff",
    zIndex: 1,
    cursor: "pointer",
  },

  sliderStyles: {
    display: "flex",
    flexFlow: "column",
    position: "relative",
    height: "100%",
  },

  dotsContainerStyles: {
    display: "flex",
    justifyContent: "center",
  },

  dotStyle: {
    margin: "0 3px",
    cursor: "pointer",
    fontSize: "20px",
  },
});
