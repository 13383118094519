import { createStyles } from "../../emotion-styles";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  container: {
    //   backgroundColor: blue[600],
    padding: 8,
    //  margin: "45px",
  },
  text: {
    // color: lightBlue[400],
    fontSize: "2.0rem",
    //  color: "text.primary",
    textAlign: "center",
  },
  contact: {
    display: "flex",
    flexFlow: "column",
    margin: 8,
    padding: 8,
  },
  address: {
    // color: lightBlue[400],
    fontSize: "1.0rem",
    //  color: "text.primary",
    //  textAlign: "center",
  },
  phone: {
    fontSize: "1.0rem",
    fontFamily: "SFBold",
  },
  map: {
    width: "100%",
    height: 430,
  },
  mapPaper: {
    padding: 8,
    margin: 8,
  },
});
