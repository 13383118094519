/** @jsxImportSource @emotion/react */
import { Typography, Paper } from "@mui/material";
import { styles } from "./styles";
import React, { useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import ImageSlider from "../../imageSlider";
import useMediaQuery from "@mui/material/useMediaQuery";
const slideImages = [
  {
    url: "images/1.png",
    title: "Slide 1",
  },
  {
    url: "images/2.png",
    title: "Slide 2",
  },
  {
    url: "images/3.png",
    title: "Slide 3",
  },
  {
    url: "images/4.png",
    title: "Slide 4",
  },
];
interface Props {}

export const AboutUs: React.FC<Props> = () => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  return (
    <div css={styles.container}>
      <Typography color="primary" css={styles.text}>
        About Acumen
      </Typography>
      <img
        src="images/aboutus/1.png"
        css={styles.image}
        style={matches ? { width: "100%", height: 127 } : {}}
      ></img>

      {/*       <Typography css={styles.banner1}>
        Santa Cruz Auto Glass Specialists
      </Typography>
      <Typography css={styles.banner2}>
        if it's glass and it's part of your car, we can help.
      </Typography> */}
      <Typography css={styles.mainParagraph}>
        Acumen Auto Glass can handle any auto glass issues. From older cars to
        high-end luxury vehicles, we can help. We also service recreational
        vehicles, buses, and even commercial trucks. We work by appointment,
        offering both in-shop (locations in both Campbell and Santa Cruz), as
        well as mobile service. Our computer-dispatched trucks provide fast,
        efficient, Quality Mobile Service. Acumen Offers a Full Lifetime
        Warranty on all automotive glass replacements. Our warranty covers
        leaks, workmanship, and issues with your moldings. Windshield Repairs
        also come with Lifetime Guarantee against the repair growing into cracks
        Acumen Auto Glass focuses on the entire customer experience. From first
        contact till we wave goodbye, we want happy customers. Our technicians
        are the best trained in the bay area. caring about our planet, we always
        follow environmentally safe practices.
      </Typography>
    </div>
  );
};
