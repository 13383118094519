/** @jsxImportSource @emotion/react */
import { EstimateInfoInput } from "./estimateInfo";
import { useMutation, gql } from "@apollo/client";

import { Typography, Paper, Button, CircularProgress } from "@mui/material"; //<FormGroup>
import { styles } from "./styles";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Opening } from "./opening";
import { ContactInfoInput } from "./contactInfo";
import { SelectWindow } from "./selectWindow";
import { MsgSent } from "./msgSent";
import { getWindow } from "./windows";
interface EstimateInfo {
  cracked?: boolean;
  insurance?: boolean;
  urgent?: boolean;
  year?: string;
  model?: string;
  make?: string;
  body?: string;
  vin?: string;
  notes?: string;
}
interface ContactInfo {
  name: string;
  address: string;
  zipcode: string;
  phone: string;
  email: string;
}
interface Props {}
const PROCESS_ESTIMATE = gql`
  mutation ProcessEstimate($info: EstimateInput) {
    processEstimate(info: $info) {
      code
    }
  }
`;

export const Estimate: React.FC<Props> = () => {
  const [msgSent, setMsgSent] = useState(false);
  const [opening, setOpening] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [windowType, setWindowType] = useState<string>("");
  const [estimateInfo, setEstimateInfo] = useState<EstimateInfo | null>(null);
  const [contactInfo, setContactInfo] = useState<ContactInfo | null>(null);
  const [tempEstimateInfo, setTempEstimateInfo] = useState<EstimateInfo | null>(
    null
  );
  const [tempContactInfo, setTempContactInfo] = useState<ContactInfo | null>(
    null
  );

  const [processEstimate] = useMutation(PROCESS_ESTIMATE, {
    onCompleted(data) {
      setShowProgress(false);
      setMsgSent(true);
    },
    onError: (err) => {
      setShowProgress(false);
      // debugger;
    },
  });
  //const navigate = useNavigate();
  const smMatch = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const onEstimateInfoComplete = (estimateInfo: EstimateInfo) => {
    setTempEstimateInfo(estimateInfo);
    setEstimateInfo(estimateInfo);
  };
  const onContactInfoComplete = (contactInfo: ContactInfo) => {
    setTempContactInfo(contactInfo);
    setContactInfo(contactInfo);
  };

  const onClickSubmit = () => {
    processEstimate({
      variables: {
        info: {
          window: getWindow(windowType)?.name,
          name: contactInfo?.name,
          phone: contactInfo?.phone,
          email: contactInfo?.email,
          zipcode: contactInfo?.zipcode,
          address: contactInfo?.address,
          year: estimateInfo?.year,
          make: estimateInfo?.make,
          model: estimateInfo?.model,
          body: estimateInfo?.body,
          vin: estimateInfo?.vin,
          cracked: estimateInfo?.cracked,
          insurance: estimateInfo?.insurance,
          urgent: estimateInfo?.urgent,
          notes: estimateInfo?.notes,
        },
      },
    });
  };

  const renderSubmitButton = () => {
    return windowType && contactInfo && estimateInfo && !msgSent ? (
      <div css={styles.buttons}>
        <Button css={styles.button} variant="contained" onClick={onClickSubmit}>
          Submit
        </Button>
      </div>
    ) : null;
  };
  const renderInfo = () => {
    if (windowType && contactInfo && estimateInfo) {
      const { name, phone, email, address, zipcode } = contactInfo;
      const {
        urgent,
        insurance,
        cracked,
        year,
        make,
        model,
        body,
        vin,
        notes,
      } = estimateInfo;
      return (
        <Paper elevation={4} css={styles.infoPaper}>
          <Typography css={styles.windowType}>
            {getWindow(windowType)?.name} window
          </Typography>
          <Paper elevation={4} css={styles.infoPaper}>
            <Typography variant="h5" css={styles.infoName}>
              Contact Info
            </Typography>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>name</Typography>
              <Typography css={styles.infoItem}>{name}</Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>phone number</Typography>
              <Typography css={styles.infoItem}>{phone}</Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>email</Typography>
              <Typography css={styles.infoItem}>{email}</Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>street address</Typography>
              <Typography css={styles.infoItem}>{address}</Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>zipcode</Typography>
              <Typography css={styles.infoItem}>{zipcode}</Typography>
            </div>
          </Paper>
          <Paper elevation={4} css={styles.infoPaper}>
            <Typography variant="h5" css={styles.infoName}>
              {" "}
              estimate info
            </Typography>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>insurance</Typography>
              <Typography css={styles.infoItem}>
                {insurance === true ? "Yes" : "No"}
              </Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>cracked</Typography>
              <Typography css={styles.infoItem}>
                {cracked === true ? "Yes" : "No"}
              </Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>urgent</Typography>
              <Typography css={styles.infoItem}>
                {urgent === true ? "Yes" : "No"}
              </Typography>
            </div>

            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>year</Typography>
              <Typography css={styles.infoItem}>{year}</Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>make</Typography>
              <Typography css={styles.infoItem}>{make}</Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>model</Typography>
              <Typography css={styles.infoItem}>{model}</Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>body</Typography>
              <Typography css={styles.infoItem}>{body}</Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>vin</Typography>
              <Typography css={styles.infoItem}>{vin}</Typography>
            </div>
            <div css={styles.infoItemContainer}>
              <Typography css={styles.infoItemLabel}>notes</Typography>
              <Typography css={styles.infoItem}>{notes}</Typography>
            </div>
          </Paper>
          <div css={styles.buttons}>
            <Button
              css={styles.button}
              variant="contained"
              onClick={() => {
                setWindowType("");
              }}
            >
              edit window
            </Button>
            <Button
              css={styles.button}
              variant="contained"
              onClick={() => {
                setEstimateInfo(null);
              }}
            >
              edit estimate
            </Button>
            <Button
              css={styles.button}
              variant="contained"
              onClick={() => {
                setContactInfo(null);
              }}
            >
              edit contact
            </Button>
          </div>
        </Paper>
      );
    } else return null;
  };
  const renderWindow = () => {
    if (msgSent)
      return (
        <MsgSent
          onAnotherClick={() => {
            setMsgSent(false);
          }}
        />
      );
    if (!opening) {
      return (
        <Opening
          onOpeningClick={() => {
            setOpening(true);
          }}
        />
      );
    } else if (!windowType) {
      return (
        <SelectWindow
          winType={windowType}
          onSelect={(key) => {
            setWindowType(key);
          }}
        />
      );
    } else if (!contactInfo) {
      return (
        <ContactInfoInput
          info={tempContactInfo}
          onComplete={onContactInfoComplete}
        />
      );
    } else if (!estimateInfo) {
      return (
        <EstimateInfoInput
          info={tempEstimateInfo}
          onComplete={onEstimateInfoComplete}
        />
      );
    }

    return renderInfo();
  };
  return (
    <div>
      {showProgress && <CircularProgress css={styles.progress} />}
      {renderWindow()}
      {renderSubmitButton()}
    </div>
  );
};
