/** @jsxImportSource @emotion/react */
import { Typography, Paper, Button } from "@mui/material"; //<FormGroup>
import { styles } from "./styles";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Props {
  onOpeningClick: () => void;
}

export const Opening: React.FC<Props> = ({ onOpeningClick }) => {
  //const navigate = useNavigate();
  const smMatch = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const render = () => {
    return (
      <Paper css={styles.openingPaper} elevation={4}>
        <Typography css={styles.openingHeading}>
          Automotive Glass Estimate
        </Typography>
        <Typography css={styles.openingParagraph}>
          Please fill in all fields. Specific information helps provide an
          accurate quote. We believe in personal service. Our quotes are
          provided by a real human. This takes a little more time. Usually only
          a few hours. Acumen Auto Glass will use your information for just this
          one quote. None of your information will ever be shared.
        </Typography>
        <Button
          variant="contained"
          css={styles.openingButton}
          onClick={onOpeningClick}
        >
          Please Choose a Window
        </Button>
      </Paper>
    );
  };
  return <>{render()}</>;
};
