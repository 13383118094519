import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Home } from "../departments/home";
import { AboutUs } from "../departments/aboutUs";
import { ContactUs } from "../departments/contactUs";
import { Directions } from "../departments/directions";
import { Gallery } from "../departments/gallery";
import { Insurance } from "../departments/insurance";
import { Mobile } from "../departments/mobile";
import { Services } from "../departments/services";
import { Estimate } from "../departments/estimate";
import { Policy } from "../departments/privacy/policy";
import { TermsOfUse } from "../departments/privacy/termsOfUse";
interface Props {}
export const AppRoutes: React.FC<Props> = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/directions" element={<Directions />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/insurance" element={<Insurance />} />
      <Route path="/mobile" element={<Mobile />} />
      <Route path="/services" element={<Services />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/termsofuse" element={<TermsOfUse />} />
      <Route path="/estimate" element={<Estimate />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};
