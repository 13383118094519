/** @jsxImportSource @emotion/react */
import { Drawer } from "@mui/material";
import { List } from "@mui/material";
import { Link } from "react-router-dom";
//import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Home from "@mui/icons-material/Settings";
import AboutUs from "@mui/icons-material/People";
import Services from "@mui/icons-material/FolderSpecial";
import Insurance from "@mui/icons-material/LocalAtm";
import Gallery from "@mui/icons-material/Collections";
import ContactUs from "@mui/icons-material/Call";
import Mobile from "@mui/icons-material/DriveEta";
import Estimate from "@mui/icons-material/Settings";
import Directions from "@mui/icons-material/Settings";
import TOU from "@mui/icons-material/ArticleOutlined";

import { useGlobalState } from "../../globalState";
import { useNavigate } from "react-router-dom";
import { styles } from "./styles";
const nav = [
  {
    icon: <Home css={styles.icon} />,
    text: "home",
    navigate: "/",
  },
  {
    icon: <AboutUs css={styles.icon} />,
    text: "about us",
    navigate: "/aboutus",
  },
  {
    icon: <Services css={styles.icon} />,
    text: "services",
    navigate: "/services",
  },
  {
    icon: <Estimate css={styles.icon} />,
    text: "estimate",
    navigate: "/estimate",
  },
  {
    icon: <Insurance css={styles.icon} />,
    text: "insurance",
    navigate: "/insurance",
  },
  {
    icon: <Gallery css={styles.icon} />,
    text: "gallery",
    navigate: "/gallery",
  },
  {
    icon: <ContactUs css={styles.icon} />,
    text: "contact us",
    navigate: "/contactus",
  },
  {
    icon: <Directions css={styles.icon} />,
    text: "directions",
    navigate: "/directions",
  },
  {
    icon: <Mobile css={styles.icon} />,
    text: "mobile",
    navigate: "/mobile",
  },
  {
    icon: <TOU css={styles.icon} />,
    text: "terms of use",
    navigate: "/termsofuse",
  },
  {
    icon: <TOU css={styles.icon} />,
    text: "policy",
    navigate: "/policy",
  },
];
export const SideBar = () => {
  const [sideBarOpen, setSideBarOpen] = useGlobalState("sideBar");
  const toggleDrawer = () => {
    setSideBarOpen(!sideBarOpen);
  };
  const list = () => (
    <List onClick={toggleDrawer}>
      {nav.map((n, index) => (
        <ListItem key={n.text} disablePadding>
          <Link to={n.navigate} css={styles.link}>
            <ListItemButton>
              <ListItemIcon>{n.icon}</ListItemIcon>
              <ListItemText primary={n.text} />
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
    </List>
  );
  return (
    <div>
      <Drawer
        anchor={"left"}
        open={sideBarOpen}
        onClose={() => setSideBarOpen(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
};
