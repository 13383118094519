/** @jsxImportSource @emotion/react */
import { Typography, Paper } from "@mui/material";
import { styles } from "./styles";
import React, { useEffect, useRef, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import useMediaQuery from "@mui/material/useMediaQuery";
const render = (status) => {
	if (status === Status.LOADING) return <h3>{status} ..</h3>;
	if (status === Status.FAILURE) return <h3>{status} ...</h3>;
	return <div />;
};
function MyMapComponent({
	center,
	zoom,
}) {
	const smMatch = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const ref = useRef();
	const [map, setMap] = useState();
	const [marker, setMarker] = useState(null);
	useEffect(() => {
		if (ref.current && !map)
			setMap(new window.google.maps.Map(ref.current, {
				center,
				zoom,
			}, [ref, map, center, zoom]));
	});
	// Add marker
	useEffect(() => {
		if (map) {
			map.setOptions({ center, zoom });
		}
		setMarker(new window.google.maps.Marker({
			position: center,
			map,
			title: "help"
		}));
	}, [map, center, zoom]);

	useEffect(() => {
		if (marker) {
			const contentString =
				'<div id="content">' +
				'<div id="siteNotice">' +
				"</div>" +
				'<h2 id="firstHeading" class="firstHeading">Acumen Glass Company</h2>' +
				'<div id="bodyContent">' +
				'<div style="display:flex; flex-flow:column;">' +
				'<div style=" font-family:SFRegular; margin-bottom:8px;">2335 Soquel Drive unit F </div>' +
				'<div style=" font-family:SFRegular; margin-bottom:8px;">Santa Cruz CA 95065</div>' +
				'<a href="tel:8314601200">(831) 460-1200</a>' +
				'<a style=" font-family:SFRegular; margin-top:8px;" target="_blank" jstcache="6" href="https://maps.google.com/maps?ll=36.989454,-121.975725&amp;z=20&amp;t=m&amp;hl=en-US&amp;gl=US&amp;mapclient=apiv3&amp;cid=1404447401472166122" tabindex="0"> <span> View on Google Maps </span> </a>' +
				"</p>" +
				"</div>" +
				"</div>";


			const msg = '<div><spanAcumen Glass Company  2335 Soquel Drive unit F, Santa Cruz CA 95065(831) 460-1200</span><a target="_blank" jstcache="6" href="https://maps.google.com/maps?ll=36.989454,-121.975725&amp;z=20&amp;t=m&amp;hl=en-US&amp;gl=US&amp;mapclient=apiv3&amp;cid=1404447401472166122" tabindex="0"> <span> View on Google Maps </span> </a></div>'

			const infoWindow = new window.google.maps.InfoWindow();
			marker.addListener("click", () => {
				debugger
				infoWindow.close();
				infoWindow.setContent(contentString);
				infoWindow.open(marker.getMap(), marker);
			});
		}

	}, [marker])
	return <div
		style={smMatch ? { height: 400 } : {}}
		ref={ref} id="map" css={styles.map} />;
}
export function Directions() {
	const center = { lat: 36.98954093137168, lng: -121.97594928430247 }; // 36.98954093137168, -121.97594928430247
	const zoom = 18;
	return (
		<div css={styles.container}>
			<Typography color="primary" css={styles.text}>
				Map
			</Typography>
			<Paper css={styles.contact} elevation={4}>
				<Typography color="primary" css={styles.address}>
					2335 Soquel Drive unit F
				</Typography>
				<Typography color="primary" css={styles.address}>
					Santa Cruz CA 95065
				</Typography>
				<a href="tel:8314601200" css={styles.phone}>
					(831) 460-1200
				</a>
			</Paper>
			<Paper css={styles.mapPaper} elevation={4}>
				<Wrapper apiKey="AIzaSyA1PJe8k398uZyoKsgFZ6j1Yjkjn6jv6r8" render={render}>
					<MyMapComponent center={center} zoom={zoom} />
				</Wrapper>
			</Paper>
		</div>
	);
}
