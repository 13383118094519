/** @jsxImportSource @emotion/react */
import { Typography, Paper, Button } from "@mui/material"; //<FormGroup>
import { styles } from "./styles";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
interface Props {
  onAnotherClick: () => void;
}
export const MsgSent: React.FC<Props> = ({ onAnotherClick }) => {
  const smMatch = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const render = () => {
    return (
      <Paper css={styles.msgSendPaper} elevation={4}>
        <Typography css={styles.openingHeading}>
          Automotive Glass Estimate
        </Typography>
        <Typography css={styles.msgSentSubHeading}>Thank you</Typography>
        <Typography css={styles.msgSentParagraph}>
          A representative will contact you shortly.
        </Typography>
        <Button
          variant="contained"
          css={styles.openingButton}
          onClick={onAnotherClick}
        >
          Would you like another estimate?
        </Button>
      </Paper>
    );
  };
  return <>{render()}</>;
};
