/** @jsxImportSource @emotion/react */
import React from "react";
import { styles } from "./styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export const Policy = () => {
  return (
    <div>
      <Grid container css={styles.container}>
        <Typography variant="h1" css={styles.h1}>
          Privacy Policy
        </Typography>
        <Typography variant="h4" css={styles.h4}>
          Last updated November 15, 2022
        </Typography>
        <Typography paragraph css={styles.p}>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </Typography>
        <Typography paragraph css={styles.p}>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.{" "}
        </Typography>
        <Typography variant="h2" css={styles.h2}>
          Interpretation and Definitions
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Interpretation
        </Typography>
        <Typography paragraph css={styles.p}>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions.
        </Typography>
        <Typography paragraph css={styles.p}>
          The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Definitions
        </Typography>
        <Typography paragraph css={styles.p}>
          For the purposes of this Privacy Policy:
        </Typography>
        <ul css={styles.ul}>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              You
            </Typography>
            <Typography paragraph css={styles.p}>
              means the individual accessing or using the Service, or Acumen
              Glass, or other legal entity on behalf of which such individual is
              accessing or using the Service, as applicable.
            </Typography>
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Company{" "}
            </Typography>
            <Typography paragraph css={styles.p}>
              {" "}
              (referred to as either "Acumen Glass", "We", "Us" or "Our" in this
              Agreement) refers to Acumen Glass, Santa Cruz, ca.
            </Typography>
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Affiliate
            </Typography>
            <Typography paragraph css={styles.p}>
              means an entity that controls, is controlled by or is under common
              control with a party, where "control" means ownership of 50% or
              more of the shares, equity interest or other securities entitled
              to vote for election of directors or other managing authority
            </Typography>
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Account
            </Typography>{" "}
            means a unique account created for You to access our Service or
            parts of our Service.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Website
            </Typography>{" "}
            refers to Acumen Glass, accessible from AcumenGlass.com
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Service
            </Typography>{" "}
            refers to the Website.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Country
            </Typography>{" "}
            refers to: California, United States
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Service Provider
            </Typography>
            <Typography paragraph css={styles.p}>
              {" "}
              means any natural or legal person who processes the data on behalf
              of Acumen Glass. It refers to third-party companies or individuals
              employed by Acumen Glass to facilitate the Service, to provide the
              Service on behalf of Acumen Glass, to perform services related to
              the Service or to assist Acumen Glass in analyzing how the Service
              is used.
            </Typography>
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Third-party Social Media Service
            </Typography>
            <Typography paragraph css={styles.p}>
              {" "}
              refers to any website or any social network website through which
              a User can log in or create an account to use the Service.
            </Typography>
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Personal Data
            </Typography>
            <Typography paragraph css={styles.p}>
              {" "}
              is any information that relates to an identified or identifiable
              individual.
            </Typography>
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Cookies
            </Typography>
            <Typography paragraph css={styles.p}>
              are small files that are placed on Your computer, mobile device or
              any other device by a website, containing the details of Your
              browsing history on that website among its many uses.
            </Typography>
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Usage Data
            </Typography>
            <Typography paragraph css={styles.p}>
              refers to data collected automatically, either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </Typography>
          </li>
        </ul>
        <Typography variant="h2" css={styles.h2}>
          Collecting and Using Your Personal Data
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Types of Data Collected
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Personal Data
        </Typography>
        <Typography paragraph css={styles.p}>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </Typography>
        <ul css={styles.ul}>
          <li css={styles.li}>Email address</li>
          <li css={styles.li}>Phone number</li>
          <li css={styles.li}>
            Address, State, Province, ZIP/Postal code, City
          </li>
          <li css={styles.li}>Usage Data</li>
        </ul>
        <Typography variant="h3" css={styles.h3}>
          Usage Data
        </Typography>
        <Typography paragraph css={styles.p}>
          Usage Data is collected automatically when using the Service.
        </Typography>
        <Typography paragraph css={styles.p}>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </Typography>
        <Typography paragraph css={styles.p}>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </Typography>
        <Typography paragraph css={styles.p}>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Tracking Technologies and Cookies
        </Typography>
        <Typography paragraph css={styles.p}>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service.
        </Typography>
        <Typography paragraph css={styles.p}>
          You can instruct Your browser to refuse all Cookies or to indicate
          when a Cookie is being sent. However, if You do not accept Cookies,
          You may not be able to use some parts of our Service.
        </Typography>
        <Typography paragraph css={styles.p}>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close your web
          browser.
        </Typography>
        <Typography paragraph css={styles.p}>
          We use both session and persistent Cookies for the purposes set out
          below:
        </Typography>
        <ul css={styles.ul}>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Necessary/Essential Cookies
            </Typography>
            <Typography css={styles.p}>Session Cookies</Typography>
            <Typography paragraph css={styles.p}>
              These Cookies are essential to provide You with services available
              through the Website and to enable You to use some of its features.
              They help to authenticate users and prevent fraudulent use of user
              accounts. Without these Cookies, the services that You have asked
              for cannot be provided, and We only use these Cookies to provide
              You with those services.
            </Typography>
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Cookies Policy/Notice Acceptance Cookies
            </Typography>
            <Typography paragraph css={styles.p}>
              These Cookies identify if users have accepted the use of cookies
              on the Website.
            </Typography>
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              Functionality Cookies
            </Typography>
            <Typography paragraph css={styles.p}>
              These Cookies allow us to remember choices You make when You use
              the Website, such as remembering your login details or language
              preference. The purpose of these Cookies is to provide You with a
              more personal experience and to avoid You having to re-enter your
              preferences every time You use the Website.
            </Typography>
          </li>
        </ul>
        <Typography paragraph css={styles.p}>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy.
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Use of Your Personal Data
        </Typography>
        <Typography paragraph css={styles.p}>
          Acumen Glass may use Personal Data for the following purposes:
        </Typography>
        <ul css={styles.ul}>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              To provide and maintain our Service
            </Typography>
            , including to monitor the usage of our Service.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              To manage Your Account:
            </Typography>{" "}
            to manage Your registration as a user of the Service. The Personal
            Data You provide can give You access to different functionalities of
            the Service that are available to You as a registered user.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              For the performance of a contract:
            </Typography>{" "}
            the development, compliance and undertaking of the purchase contract
            for the products, items or services You have purchased or of any
            other contract with Us through the Service.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              To contact You:
            </Typography>{" "}
            To contact You by email, telephone calls, SMS, or other equivalent
            forms of electronic communication, such as a mobile application's
            push notifications regarding updates or informative communications
            related to the functionalities, products or contracted services,
            including the security updates, when necessary or reasonable for
            their implementation.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              To provide You
            </Typography>{" "}
            with news, special offers and general information about other goods,
            services and events which we offer that are similar to those that
            you have already purchased or enquired about unless You have opted
            not to receive such information.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              To manage Your requests:
            </Typography>{" "}
            To attend and manage Your requests to Us.
          </li>
        </ul>
        <Typography paragraph css={styles.p}>
          We may share your personal information in the following situations:
        </Typography>
        <ul css={styles.ul}>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              With Service Providers:
            </Typography>{" "}
            We may share Your personal information with Service Providers to
            monitor and analyze the use of our Service, to show advertisements
            to You to help support and maintain Our Service, to contact You, to
            advertise on third party websites to You after You visited our
            Service or for payment processing.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              For Business transfers:
            </Typography>{" "}
            We may share or transfer Your personal information in connection
            with, or during negotiations of, any merger, sale of Company assets,
            financing, or acquisition of all or a portion of our business to
            another company.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              With Affiliates:
            </Typography>{" "}
            We may share Your information with Our affiliates, in which case we
            will require those affiliates to honor this Privacy Policy.
            Affiliates include Our parent company and any other subsidiaries,
            joint venture partners or other companies that We control or that
            are under common control with Us.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              With Business partners:
            </Typography>{" "}
            We may share Your information with Our business partners to offer
            You certain products, services or promotions.
          </li>
          <li css={styles.li}>
            <Typography varient="h3" css={styles.h3}>
              With other users:
            </Typography>{" "}
            when You share personal information or otherwise interact in the
            public areas with other users, such information may be viewed by all
            users and may be publicly distributed outside. If You interact with
            other users or register through a Third-Party Social Media Service,
            Your contacts on the Third-Party Social Media Service may see Your
            name, profile, pictures and description of Your activity. Similarly,
            other users will be able to view descriptions of Your activity,
            communicate with You and view Your profile.
          </li>
        </ul>
        <Typography variant="h3" css={styles.h3}>
          Retention of Your Personal Data
        </Typography>
        <Typography paragraph css={styles.p}>
          Acumen Glass will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </Typography>
        <Typography paragraph css={styles.p}>
          Acumen Glass will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Transfer of Your Personal Data
        </Typography>
        <Typography paragraph css={styles.p}>
          Your information, including Personal Data, is processed at Acumen
          Glass's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </Typography>
        <Typography paragraph css={styles.p}>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </Typography>
        <Typography paragraph css={styles.p}>
          Acumen Glass will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Disclosure of Your Personal Data
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Business Transactions
        </Typography>
        <Typography paragraph css={styles.p}>
          If Acumen Glass is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Law enforcement
        </Typography>
        <Typography paragraph css={styles.p}>
          Under certain circumstances, Acumen Glass may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </Typography>
        <Typography variant="h3" css={styles.h3}>
          Other legal requirements
        </Typography>
        <Typography paragraph css={styles.p}>
          Acumen Glass may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </Typography>
        <ul css={styles.ul}>
          <li css={styles.li}>Comply with a legal obligation</li>
          <li css={styles.li}>
            Protect and defend the rights or property of Acumen Glass
          </li>
          <li css={styles.li}>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li css={styles.li}>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li css={styles.li}>Protect against legal liability</li>
        </ul>
        <Typography variant="h3" css={styles.h3}>
          Security of Your Personal Data
        </Typography>
        <Typography paragraph css={styles.p}>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </Typography>
        <Typography variant="h2" css={styles.h2}>
          Children's Privacy
        </Typography>
        <Typography paragraph css={styles.p}>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </Typography>
        <Typography paragraph css={styles.p}>
          We also may limit how We collect, use, and store some of the
          information of Users between 13 and 18 years old. In some cases, this
          means We will be unable to provide certain functionality of the
          Service to these users.
        </Typography>
        <Typography paragraph css={styles.p}>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </Typography>
        <Typography variant="h2" css={styles.h2}>
          Links to Other Websites
        </Typography>
        <Typography paragraph css={styles.p}>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </Typography>
        <Typography paragraph css={styles.p}>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </Typography>
        <Typography variant="h2" css={styles.h2}>
          Changes to this Privacy Policy
        </Typography>
        <Typography paragraph css={styles.p}>
          We may update our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </Typography>
        <Typography paragraph css={styles.p}>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </Typography>
        <Typography paragraph css={styles.p}>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Typography>
        <Typography variant="h2" css={styles.h2}>
          Contact Us
        </Typography>
        <Typography paragraph css={styles.p}>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </Typography>
        <ul css={styles.ul}>
          <li css={styles.li}>By email: info@AcumenAutoGlass.com</li>
        </ul>
      </Grid>
    </div>
  );
};
export default Policy;
