import { createStyles } from "../../emotion-styles";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  container: {
    //   backgroundColor: blue[600],
    padding: "2em",
    //  margin: "45px",
  },
  text: {
    // color: lightBlue[400],
    fontSize: "2.0rem",
    //  color: "text.primary",
    textAlign: "center",
  },
  address: {
    marginBottom: 8,
  },
  city: { marginBottom: 8 },
  phone: { marginBottom: 8 },
});
