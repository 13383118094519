import { createStyles } from "../emotion-styles";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  container: {
    //   backgroundColor: blue[600],
    padding: "2em",
    // margin: "45px",
  },
  drawer: {
    background: "red",
  },
  text: {
    color: "rgb(252,190,7)",
    fontSize: "2.0rem",
    //  color: "text.primary",
    textAlign: "center",
  },
  icon: {
    color: "rgb(252,190,7)",
  },
  link: {
    color: "#856710",
    textDecoration: "none",
  },
});
