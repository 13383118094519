/** @jsxImportSource @emotion/react */
import { Typography, Paper } from "@mui/material";
import { styles } from "./styles";

interface Props {}
export const Insurance: React.FC<Props> = () => {
  return (
    <div css={styles.container}>
      <Typography color="primary" css={styles.text}>
        Insurance Services
      </Typography>
      <Typography css={styles.mainParagraph}>
        Acumen Auto Glass works with all automotive insurance companies. We will
        be happy to guide you through the claims process. We can help you
        determine whether it's worth reporting your insurance claim. Acumen uses
        the Electronic Data Interchange to speed up setting up your claim. With
        Insurance claims all you pay is your set deductible. When the job is
        completed, we directly bill the balance to your insurance company. Glass
        claims are simple and fast. Insurance companies rarely raise premiums
        because of a glass claim. Breaking is usually outside of a driver's
        control. They know the danger of driving around with a cracked
        windshield and would rather have it repaired. Check with your mobile
        agent for more information about costs and your policy's coverage.
      </Typography>

      <Paper elevation={4} css={styles.container}>
        <Typography color="primary" css={styles.bulletText}>
          Some Important Points
        </Typography>
        <ul css={styles.ul}>
          <li css={styles.li}>
            Many Insurance Companies will waive your deductible, paying the full
            cost of rock chips repairs.
          </li>
          <li css={styles.li}>
            Original Factory Replacement parts must be approved on your
            insurance company, Be sure to let your agent, when you setup your
            clain, that Original Factory Replacement glass is important to.
          </li>
          <li css={styles.li}>
            Don’t Be Steered! Steering is a common technique used by some
            insurance companies. This is where they setup deals with a speicific
            glass company and steer there insurors to them. This is actually
            illegal and often doesn't provide you with the highest quality.
          </li>
        </ul>
      </Paper>
    </div>
  );
};
