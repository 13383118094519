// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import apolloClient from "./constants/apollo";
// import { BrowserRouter as Router } from "react-router-dom";
// import theme from "./theme";
// import {
//   ApolloProvider,
//   ApolloClient,
//   InMemoryCache,
//   createHttpLink,
// } from "@apollo/client";
// import { setContext } from "@apollo/client/link/context";
// console.log(theme);
// const httpLink = createHttpLink({
//   uri: apolloClient,
// });
// const authLink = setContext((_, { headers }) => {
//   const auth_token = localStorage.getItem("auth_token");
//   return {
//     headers: {
//       ...headers,
//       authorization: auth_token ? `Bearer ${auth_token}` : "",
//     },
//   };
// });
// const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   uri: apolloClient,
//   cache: new InMemoryCache(),
// });
// if (localStorage.getItem("auth_token")) {
//   localStorage.removeItem("auth_token");
// }
// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <Router>
//       <ApolloProvider client={client}>
//         <App />
//       </ApolloProvider>
//     </Router>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import apolloClient from "./constants/apollo";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { green, grey, blue, brown, red, blueGrey } from "@mui/material/colors";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      contrastText: "rgb(252,190,7)", //yellow[600],
      dark: "rgb(100, 141, 174)",
      light: blue[700],
      main: blue[900],
    },
    secondary: {
      contrastText: "#111",
      dark: "rgb(171, 0, 60)",
      light: "rgb(247, 51, 120)",
      main: "rgb(252,190,7)", //yellow[600],
    },
    text: {
      disabled: "rgba(0, 0, 0, 0.38)",
      primary: grey[900],
      secondary: "rgba(0, 0, 0, 0.6)",
    },
    info: {
      contrastText: "#fff",
      dark: "#01579b",
      light: "#03a9f4",
      main: "#0288d1",
    },
    background: {
      default: red[900],
      //   paper: blue[100],
    },
    error: {
      main: red[900],
      light: red[900],
      dark: red[900],
      contrastText: grey[50],
    },
    common: {
      black: grey[900],
      white: grey[50],
    },
  },
  typography: {
    //fontSize: 12,
    fontFamily: ["SFRegular"].join(","),
  },
});

const httpLink = createHttpLink({
  uri: apolloClient,
});
const authLink = setContext((_, { headers }) => {
  const auth_token = localStorage.getItem("auth_token");
  return {
    headers: {
      ...headers,
      authorization: auth_token ? `Bearer ${auth_token}` : "",
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  uri: apolloClient,
  cache: new InMemoryCache(),
});
if (localStorage.getItem("auth_token")) {
  localStorage.removeItem("auth_token");
}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
