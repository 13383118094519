import { fontSize } from "@mui/system";
import { createStyles } from "../../emotion-styles";
import glassdiagramImage from "./images/glassdiagram.jpg";
import { red, blue } from "@mui/material/colors";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  container: {
    //   backgroundColor: blue[600],
    padding: "2em",
    //  margin: "45px",
  },
  checkboxes: {
    display: "flex",
    flexFlow: "column nowrap",
    margin: 8,
    padding: 8,
  },
  checkbox: {},
  info: {
    margin: 8,
    padding: 8,
  },
  inputs: {
    display: "flex",
    flexFlow: "column",
  },
  input: {
    marginBottom: 8,
  },
  contactInfo: {},
  autoInfo: {},
  infoHeading: {
    textAlign: "center",
    fontFamily: "SFBold",
    fontSize: "1.25rem",
  },
  submitButton: {},
  text: {
    // color: lightBlue[400],
    fontSize: "2.0rem",
    //  color: "text.primary",
    marginTop: 8,
    textAlign: "center",
  },
  selectedWindow: {
    position: "absolute",
    backgroundColor: red[200],
    opacity: 0.6,
    borderRadius: 8,
  },
  notSelectedWindow: {
    position: "absolute",
  },

  background: {
    height: 400,
    backgroundImage: `url(${glassdiagramImage})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: 300,
    margin: "auto auto",
    position: "absolute",
  },
  windows: {
    width: 300,
    height: 400,
  },
  window: {
    border: "solid",
    position: "absolute",
  },
  selected: {
    fontFamily: "SFBold",
    textAlign: "center",
    fontSize: "1,25rem",
    height: 32,
    textTransform: "capitalize",
  },
  infoPaper: {
    margin: 8,
    paddingBottom: 8,
  },
  infoItem: { padding: 8 },
  infoItemLabel: {
    fontFamily: "SFBold",
    marginRight: 8,
    marginLeft: 8,
  },
  infoItemContainer: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
  },
  textAreaContainer: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
  },
  requiredFields: {
    marginLeft: 8,
    fontFamily: "SFRegularItalic",
    color: red[700],
  },
  infoName: {
    color: blue[700],
    marginLeft: 4,
    fontFamily: "SFBold",
    fontSize: "1rem",
    textTransform: "uppercase",
  },
  selectPaper: {
    margin: "auto",
    padding: 8,
    width: 324,
    height: 530,
  },
  openingPaper: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    padding: 8,
    margin: 16,
  },
  msgSendPaper: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "16px 16px 32px 16px",
    margin: 16,
    alignItems: "center",
  },
  openingButton: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  openingHeading: {
    fontFamily: "SFBold",
    fontSize: "1.5rem",
  },
  openingParagraph: {
    fontFamily: "SFRegular",
    fontSize: "1.0rem",
  },
  msgSentHeading: { fontFamily: "SFBold", fontSize: "1.5rem" },
  msgSentSubHeading: { fontFamily: "SFRegularItalic", fontSize: "1.5rem" },
  msgSentParagraph: {
    fontFamily: "SFRegular",
    fontSize: "1.0rem",
    margin: 8,
  },
  windowType: {
    fontFamily: "SFBold",
    fontSize: "1.25rem",
    textAlign: "center",
    textTransform: "capitalize",
  },
  buttons: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "right",
    alignContent: "center",
  },
  button: {
    marginRight: 8,
    marginLeft: 8,
  },
  progress: {
    zIndex: 2,
    position: "fixed",
    top: "50%",
    left: "50%",
  },
  notes: {
    margin: 8,
    width: "calc(100% - 24px)",
  },
});
