/** @jsxImportSource @emotion/react */
import { Typography, Paper } from "@mui/material";
import { styles } from "./styles";

interface Props {}
export const ContactUs: React.FC<Props> = () => {
  return (
    <div css={styles.container}>
      <Typography color="primary" css={styles.text}>
        Contact Information
      </Typography>

      <Typography color="primary" css={styles.address}>
        2335 Soquel Drive unit F
      </Typography>
      <Typography color="primary" css={styles.city}>
        Santa Cruz CA 95065
      </Typography>
      <Typography color="primary" css={styles.phone}>
        <a href="tel:8314601200"> (831) 460-1200</a>
      </Typography>
    </div>
  );
};
