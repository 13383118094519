const abs = [38, -4];
const rows = [abs[1] + 52, abs[1] + 196, abs[1] + 308];
const height = 40;
export const Windows = [
  {
    key: "leftFrontVent",
    name: "left front vent",
    style: {
      top: rows[0],
      left: abs[0] + 72,
      width: 20,
      height: height,
    },
  },
  {
    key: "leftFrontDoor",
    name: "left front door",
    style: {
      top: rows[0],
      left: abs[0] + 72 + 20,
      width: 32,
      height: height,
    },
  },
  {
    key: "leftBackSeat",
    name: "left back seat",
    style: {
      top: rows[0],
      left: abs[0] + 72 + 20 + 32,
      width: 36,
      height: height,
    },
  },
  {
    key: "leftBackVent",
    name: "left back vent",
    style: {
      top: rows[0],
      left: abs[0] + 72 + 20 + 32 + 36,
      width: 16,
      height: height,
    },
  },
  {
    key: "leftQuarter",
    name: "left quarter",
    style: {
      top: rows[0],
      left: abs[0] + 72 + 20 + 32 + 36 + 16,
      width: 16,
      height: height,
    },
  },

  {
    key: "rightQuarter",
    name: "right quarter",
    style: {
      top: rows[1],
      left: abs[0] + 34,
      width: 18,
      height: height,
    },
  },
  {
    key: "rightBackVent",
    name: "right back vent",
    style: {
      top: rows[1],
      left: abs[0] + 34 + 18,
      width: 14,
      height: height,
    },
  },
  {
    key: "rightBackSeat",
    name: "right back seat",
    style: {
      top: rows[1],
      left: abs[0] + 34 + 18 + 14,
      width: 34,
      height: height,
    },
  },
  {
    key: "rightFrontDoor",
    name: "right front door",
    style: {
      top: rows[1],
      left: abs[0] + 34 + 18 + 14 + 34,
      width: 38,
      height: height,
    },
  },
  {
    key: "rightFrontVent",
    name: "right front vent",
    style: {
      top: rows[1],
      left: abs[0] + 34 + 18 + 14 + 34 + 38,
      width: 20,
      height: height,
    },
  },
  {
    key: "windshield",
    name: "windshield",
    style: {
      top: rows[2],
      left: abs[0],
      width: 98,
      height: height,
    },
  },
  {
    key: "back",
    name: "back",
    style: {
      top: rows[2],
      left: abs[0] + 24 + 98,
      width: 98,
      height: height,
    },
  },
];

export const getWindow = (key: string) => {
  return Windows.find((window) => key === window.key);
};
export default Windows;
