/** @jsxImportSource @emotion/react */
import { Typography, Paper, Button } from "@mui/material"; //<FormGroup>
import { Windows, getWindow } from "./windows";
import { styles } from "./styles";
import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Props {
  onSelect: (key: string) => void;
  winType: string | null;
}

export const SelectWindow: React.FC<Props> = ({ onSelect, winType }) => {
  const [windowType, setWindowType] = useState(
    winType === null ? "none" : winType
  );
  const onClickSubmit = () => {
    onSelect(windowType);
  };
  const onWindowClick = (key: any) => {
    setWindowType(key);
  };
  const renderWindows = () => {
    const w = Windows.map((win, i) => {
      return (
        <span
          key={win.key}
          style={win.style}
          css={
            windowType === win.key
              ? styles.selectedWindow
              : styles.notSelectedWindow
          }
          onClick={() => onWindowClick(win.key)}
        ></span>
      );
    });
    //return <div css={styles.background}>{renderWindows()}</div>

    return (
      <div css={styles.windows}>
        <div css={styles.background}>{w}</div>
      </div>
    );
  };
  const renderSubmitButton = () => {
    return true ? (
      <Button
        css={styles.submitButton}
        variant="contained"
        fullWidth
        onClick={onClickSubmit}
      >
        Submit
      </Button>
    ) : null;
  };
  const renderSelection = () => {
    if (windowType !== "none") {
      const w = getWindow(windowType);
      return <Typography css={styles.selected}>{w?.name}</Typography>;
    }
    return <div css={styles.selected} />;
  };
  const render = () => {
    return (
      <Paper css={styles.selectPaper} elevation={4}>
        {renderSelection()}
        {renderWindows()}
        {renderSubmitButton()}
      </Paper>
    );
  };
  return <>{render()}</>;
};

export default SelectWindow;
